import router from 'next/router'
import { DialogType } from 'store/dialogs.slice'

export interface IPersistentReminderList {
  [key: string]: IPersistentReminder[]
}

export interface IPersistentReminder {
  beginTime: number
  hash: number
}

const PERSISTENT_REMINDER_LIST_KEY = 'spvnReminderList'
let persistentReminderList: IPersistentReminder[]

export const getPersistentReminderList = (): IPersistentReminder[] => {
  if (!persistentReminderList) {
    persistentReminderList = JSON.parse(localStorage.getItem(PERSISTENT_REMINDER_LIST_KEY) || '[]')

    //* Remove outdated reminder Data from local Storage to avoid full of storage
    persistentReminderList.forEach((reminder, idx) => {
      if (reminder.beginTime <= Date.now()) persistentReminderList.splice(idx, 1)
    })
    //* Write the filtered data to local storage
    localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
  }
  return persistentReminderList
}

export const updatePersistentReminderList = (reminderList: IPersistentReminder[]) => {
  persistentReminderList = reminderList
  localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
}

const PERSISTENT_NEXT_GAME_REMINDER = 'spvnNextGameReminder'

export const getPersistentNextGameReminderHash = () => {
  if (typeof window !== 'undefined') {
    return +(localStorage.getItem(PERSISTENT_NEXT_GAME_REMINDER) || -1)
  } else return -1
}

export const setPersistentNextGameReminderHash = (hash: number) => {
  localStorage.setItem(PERSISTENT_NEXT_GAME_REMINDER, '' + hash)
}

const PERSISTENT_DIALOG_KEY = 'spvnMBDialog'
interface IpersistentDialog {
  [key: string]: { dialog?: DialogType; willRemovePersistentOnOpen?: boolean }
}

export const getPersistentDialog = () => {
  const eventCode = router.query.cid as string
  const persistentDialog: IpersistentDialog = JSON.parse(localStorage.getItem(PERSISTENT_DIALOG_KEY) || '{}')
  return persistentDialog[eventCode]
}

export const setPersistentDialog = (dialog?: DialogType, willRemovePersistentOnOpen?: boolean) => {
  const eventCode = router.query.cid as string
  const persistentDialog: IpersistentDialog = { [eventCode]: { dialog, willRemovePersistentOnOpen } }
  localStorage.setItem(PERSISTENT_DIALOG_KEY, JSON.stringify(persistentDialog))
}

export const updatePersistentDialog = ({
  selectingEvent,
  willRemovePersistentOnOpen,
}: {
  selectingEvent: string
  willRemovePersistentOnOpen: boolean
}) => {
  let persistentDialog: IpersistentDialog = JSON.parse(localStorage.getItem(PERSISTENT_DIALOG_KEY) || '{}')
  persistentDialog = { [selectingEvent]: { ...persistentDialog[selectingEvent], willRemovePersistentOnOpen } }
  localStorage.setItem(PERSISTENT_DIALOG_KEY, JSON.stringify(persistentDialog))
}

export const removePersistentDialog = () => {
  localStorage.removeItem(PERSISTENT_DIALOG_KEY)
}
