import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'shared/axios-settings'
import {
  ICommonEventSetting,
  IEventConfigResponse,
  IMilestoneSetting,
  IMilestoneUserStatusResponse,
  INumberOpenedBoxResponse,
  IOpenBoxResponse,
  IOpenMilestoneBoxResponse,
  IRedemptionCodeSetting,
  IReminderNextGameSetting,
  IUserChanceResponse,
} from 'types'
import { setBoxIdxOpened } from 'store/app.slice'
import { DialogType, openDialog } from './dialogs.slice'
import { IAxiosBaseQueryError } from 'shared/axios-settings'
import { setSlotId, timeOffset } from 'shared/utils'

// Define a service using a base URL and expected endpoints
const apiSlice = createApi({
  reducerPath: 'campaginApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Event', 'Tasks', 'Chance', 'OpenedBox', 'EventOpenedBox', 'MilestoneUserStatus'],
  endpoints: (builder) => {
    return {
      getEventConfig: builder.query<IEventConfigResponse, string>({
        query: (eventCode) => ({
          url: `/event/?event_code=${eventCode}`,
        }),
        providesTags: ['Event'],
        transformResponse: (res: {
          event_config_json: string
          begin_time: number
          end_time: number
          gp_slot_id: number
          reminder_next_game_setting: IReminderNextGameSetting
          milestone_setting: IMilestoneSetting
          common_event_setting: ICommonEventSetting
          redemption_code_setting: IRedemptionCodeSetting
          milestones: {
            text_milestone_name: string
            number_target_achievement_milestone: number
          }[]
          number_opened_boxes_setting: {
            color_text_display_number_open_box_actions: string[]
            image_display_number_open_box_actions: string
            radio_display_number_open_box_actions: 'yes' | 'no'
          }
        }) => {
          const trans = JSON.parse(res.event_config_json)
          trans.begin_time = res.begin_time + timeOffset
          trans.end_time = res.end_time + timeOffset

          //Handle new API response
          trans.common_event_setting = res.common_event_setting
          trans.redemption_code_setting = res.redemption_code_setting

          trans.reminder_next_game_setting = {}
          Object.keys(res.reminder_next_game_setting || {}).forEach((field) => {
            if (field.startsWith('color'))
              trans.reminder_next_game_setting[field] = res.reminder_next_game_setting[field]?.[0]
            else trans.reminder_next_game_setting[field] = res.reminder_next_game_setting[field]
          })

          trans.milestone_setting = {}
          Object.keys(res.milestone_setting || {}).forEach((field) => {
            if (field.startsWith('color')) trans.milestone_setting[field] = res.milestone_setting[field]?.[0]
            else if (field.startsWith('text_color'))
              trans.milestone_setting[field] = {
                text: res.milestone_setting[field]?.[0],
                color: res.milestone_setting[field]?.[1],
              }
            else trans.milestone_setting[field] = res.milestone_setting[field]
          })

          trans.number_opened_boxes_setting = res.number_opened_boxes_setting
          trans.number_opened_boxes_setting.color_text_display_number_open_box_actions =
            res.number_opened_boxes_setting.color_text_display_number_open_box_actions?.[0]

          setSlotId(res.gp_slot_id)
          Object.keys(trans).forEach((field) => {
            if (field.startsWith('color')) trans[field] = trans[field]?.[0]
            if (field.startsWith('text_color'))
              trans[field] = { text: trans[field]?.[0], color: trans[field]?.[1]?.[0] }
            if (field === 'item_list_ldc_id') trans[field] = trans[field]?.[0]
          })

          trans.milestones = res.milestones
          return trans
        },
      }),

      getUserChance: builder.query<IUserChanceResponse, { eventCode: string }>({
        query: ({ eventCode }) => ({
          url: `/user-chance/?event_code=${eventCode}`,
        }),
        providesTags: ['Chance'],
      }),

      openBox: builder.mutation<IOpenBoxResponse, { boxNo: number; eventCode: string }>({
        query: ({ boxNo, eventCode }) => ({
          url: `/open-box/`,
          method: 'POST',
          data: {
            box_no: boxNo,
            event_code: eventCode,
          },
        }),
        invalidatesTags: ['OpenedBox', 'MilestoneUserStatus'],
        async onQueryStarted({ boxNo, eventCode }, { dispatch, queryFulfilled }) {
          try {
            dispatch(setBoxIdxOpened(boxNo - 1))
            const { data } = await queryFulfilled
            dispatch(
              apiSlice.util.updateQueryData('getUserChance', { eventCode }, (draft) => {
                Object.assign(draft, data.chance_info)
              }),
            )
            setTimeout(() => {
              // Wait for open box animation
              dispatch(
                openDialog({ dialogType: DialogType.ClaimPrizeDialog, prize: data.prize, boxData: { from: 'grid' } }),
              )
            }, 1900)
          } catch (err) {
            // release lock of open box when open box api have error
            dispatch(setBoxIdxOpened(null))
            const { error } = err as { error: IAxiosBaseQueryError }
            if (error.status === 500) dispatch(openDialog({ dialogType: DialogType.ErrorPopup }))
            switch (error.data?.code) {
              case 4009: // out of chance
                dispatch(openDialog({ dialogType: DialogType.TaskDialog }))
                break
              case 4037: // event not started
              case 2036: // slot ended
              case 2044: // invalid box number
              default:
                dispatch(openDialog({ dialogType: DialogType.ErrorPopup }))
                break
            }
          }
        },
      }),
      getNumberOfOpenedBox: builder.query<INumberOpenedBoxResponse, { eventCode: string }>({
        query: ({ eventCode }) => ({
          url: `/number-opened-boxes/?event_code=${eventCode}`,
        }),
        providesTags: ['OpenedBox'],
      }),
      getEventNumberOfOpenedBox: builder.query<INumberOpenedBoxResponse, { eventCode: string }>({
        query: ({ eventCode }) => ({
          url: `/event-number-opened-boxes/?event_code=${eventCode}`,
        }),
        providesTags: ['EventOpenedBox'],
      }),
      getMilestoneUserStatus: builder.query<IMilestoneUserStatusResponse, { eventCode: string }>({
        query: ({ eventCode }) => ({
          url: `/milestone/user-status/?event_code=${eventCode}`,
        }),
        providesTags: ['MilestoneUserStatus'],
      }),
      openMilestoneBox: builder.mutation<
        IOpenMilestoneBoxResponse,
        { event_code: string; milestone_id: number; milestone_no: number }
      >({
        query: ({ event_code, milestone_id, milestone_no }) => ({
          url: `/milestone/open-box/`,
          method: 'POST',
          data: {
            milestone_id: milestone_id,
            event_code,
          },
        }),
        invalidatesTags: ['MilestoneUserStatus'],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            dispatch(
              openDialog({
                dialogType: DialogType.ClaimPrizeDialog,
                prize: data.prize,
                boxData: { from: 'milestone', boxNo: arg.milestone_no },
              }),
            )
          } catch (err) {
            const { error } = err as { error: IAxiosBaseQueryError }
            switch (error.data?.code) {
              case 4009: // out of chance
                dispatch(openDialog({ dialogType: DialogType.TaskDialog }))
                break
              case 4037: // event not started
              case 2036: // slot ended
              case 2044: // invalid box number
              case 3002: // not found slot
              case 3026: // not found milestone
              case 2046: // milestone not reached
              case 2047: // milestone already rewarded
              default:
                dispatch(openDialog({ dialogType: DialogType.ErrorPopup }))
                break
            }
          }
        },
      }),
    }
  },
})

export const {
  useGetEventConfigQuery,
  useGetUserChanceQuery,
  useOpenBoxMutation,
  useGetMilestoneUserStatusQuery,
  useGetNumberOfOpenedBoxQuery,
  useOpenMilestoneBoxMutation,
  useGetEventNumberOfOpenedBoxQuery,
} = apiSlice

export default apiSlice
