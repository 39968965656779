import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'
import apiSlice from './api.slice'
import homeReducer, { name as homeSliceName } from './app.slice'
import shareAPISlice from './share-api.slice'
import dialogsReducer, { name as dialogsSliceName } from './dialogs.slice'
import toastReducer, { name as toastSliceName } from './toast.slice'
import taskAPISlice from './task-api.slice'

const rootReducer = combineReducers({
  [homeSliceName]: homeReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [dialogsSliceName]: dialogsReducer,
  [toastSliceName]: toastReducer,
  [shareAPISlice.reducerPath]: shareAPISlice.reducer,
  [taskAPISlice.reducerPath]: taskAPISlice.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.ENV !== 'live',
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware(),
      apiSlice.middleware,
      // shareAPISlice.middleware,
      taskAPISlice.middleware,
    ]
  },
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
