import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'shared/axios-settings'
import {
  IAddShareResponse,
  IGetFriendListResponse,
  IGetShareLinkResponse,
  IShareViaChatParams,
  ISharingHandler,
} from 'types'

interface IGetFriendListServerResponse extends IGetFriendListResponse {
  pagination: {
    limit: number
    offset: number
  }
  code: number
  msg: string
}

const shareAPISlice = createApi({
  reducerPath: 'shareApi',
  tagTypes: ['Friend', 'Link'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => {
    return {
      getFriendList: builder.query<
        IGetFriendListResponse,
        { eventCode: string; deviceID: string; limit?: number; offset?: number }
      >({
        query: ({ eventCode, deviceID, offset = 0, limit = 10 }) => ({
          url: `/share/friends/?event_code=${eventCode}&device_id=${deviceID}&offset=${offset}&limit=${limit}`,
        }),
        transformResponse: (response: IGetFriendListServerResponse) => ({
          data: response.data,
          total: response.total,
        }),
        providesTags: (result) => {
          if (result && result.data) {
            return [...result.data?.map(({ user_id }) => ({ type: 'Friend' as const, id: user_id }))]
          } else {
            return ['Friend']
          }
        },
      }),
      getShareLink: builder.query<IGetShareLinkResponse, ISharingHandler>({
        query: ({ eventCode, micrositeID, snsType, objectType }) => ({
          url: `/share/share-link/?event_code=${eventCode}&microsite_id=${micrositeID}&sns_type=${snsType}&object_type=${objectType}`,
        }),
        providesTags: ['Link'],
      }),
      shareViaChat: builder.mutation<unknown, IShareViaChatParams>({
        query: ({ shareID, micrositeID, toUserID, appSignature }) => ({
          url: `/share/share-via-chat/`,
          method: 'POST',
          data: {
            share_id: shareID,
            microsite_id: micrositeID,
            dest_user_id: toUserID,
            app_signature: appSignature,
          },
        }),
      }),
      addShare: builder.mutation<IAddShareResponse, { shareID: string; toUserID?: number }>({
        query: ({ shareID, toUserID = 0 }) => ({
          url: `/share/add-share/`,
          method: 'POST',
          data: {
            share_id: shareID,
            dest_shopee_user_id: toUserID,
          },
        }),
        // invalidatesTags: (result, error, arg) => [{ type: 'Friend', id: arg.toUserID }],
      }),
    }
  },
})

export const { useLazyGetFriendListQuery, useLazyGetShareLinkQuery, useAddShareMutation, useShareViaChatMutation } =
  shareAPISlice

export default shareAPISlice
