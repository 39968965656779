import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EventStatus } from 'types'

export const name = 'app'

interface intitialStateProps {
  isLogin: boolean
  eventStatus: EventStatus
  boxIdxOpened: number | null
  error: { status: number; data: { code: number } } | null
}

const initialState: intitialStateProps = {
  isLogin: true,
  eventStatus: null,
  boxIdxOpened: null,
  error: null,
}

const homeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIsLogin: (state, action: PayloadAction<intitialStateProps['isLogin']>) => {
      state.isLogin = action.payload
    },
    setError: (state, action: PayloadAction<intitialStateProps['error']>) => {
      state.error = action.payload
    },
    setEventStatus: (state, action: PayloadAction<intitialStateProps['eventStatus']>) => {
      state.eventStatus = action.payload
    },
    setBoxIdxOpened: (state, action: PayloadAction<intitialStateProps['boxIdxOpened']>) => {
      state.boxIdxOpened = action.payload
    },
  },
})

export const {
  actions: { setIsLogin, setError, setEventStatus, setBoxIdxOpened },
} = homeSlice

export default homeSlice.reducer
