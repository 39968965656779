import { createApi } from '@reduxjs/toolkit/query/react'
import { AxiosRequestHeaders } from 'axios'
import { axiosBaseQuery } from 'shared/axios-settings'
import { IGetTasksResponse, IPerformBatchTasksRequest, IPerformBatchTasksResponse, ITask } from 'types'
import apiSlice from './api.slice'

const taskAPISlice = createApi({
  reducerPath: 'taskApi',
  tagTypes: ['Tasks'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => {
    return {
      getTasks: builder.query<IGetTasksResponse, { eventCode: string }>({
        query: ({ eventCode }) => ({
          url: `${process.env.BASE_API_URL}/delta/tasks/?event_code=${eventCode}`,
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
        }),
        providesTags: ['Tasks'],
      }),

      performTask: builder.mutation<unknown, { eventCode: string; taskId: ITask['id'] }>({
        query: ({ eventCode, taskId }) => ({
          url: `${process.env.BASE_API_URL}/delta/tasks/`,
          method: 'POST',
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
          data: {
            event_code: eventCode,
            task_id: taskId,
          },
        }),
      }),

      performBatchTasks: builder.mutation<IPerformBatchTasksResponse, IPerformBatchTasksRequest>({
        query: (data) => ({
          url: `${process.env.BASE_API_URL}/delta/tasks/batch/`,
          method: 'POST',
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
          data,
        }),
        async onQueryStarted(queryData, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
            if (!data.prize_awarded) return
            dispatch(
              apiSlice.util.updateQueryData(
                'getUserChance',
                { eventCode: queryData.requests[0].event_code },
                (draft) => {
                  draft.total_balance = data.prize_awarded[0].chance_info.accumulate_chance
                },
              ),
            )
          } catch (err) {
            //handle err here
          }
        },
      }),
    }
  },
})

export const { useGetTasksQuery, useLazyGetTasksQuery, usePerformBatchTasksMutation } = taskAPISlice

export default taskAPISlice
