// import dayjs from 'dayjs'
import i18next from 'i18next'
import router from 'next/router'
import store from 'store'
import { closeDialog, DialogType, openDialog } from 'store/dialogs.slice'
import taskAPISlice from 'store/task-api.slice'
import { IShowToastPayload, toastActions } from 'store/toast.slice'
import { BoxSizeType, EventStatus, ITask, ITimer, ShareObjectType, TaskType } from 'types'
import { setPersistentDialog } from './persistent'
import { isPC, micrositeNavigate } from './webBridge'

export const convertAppPath = (url: string): string => {
  const base64HomeUrl = window.btoa(
    JSON.stringify({
      paths: [{ webNav: { url } }],
    }),
  )
  return `home?navRoute=${window.encodeURIComponent(base64HomeUrl)}`
}

export const convertAppRL = (url: string) => `home?apprl=${encodeURIComponent(decodeURIComponent(url))}`

export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') return null
  return (
    document?.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1] || null
  )
}

export const requestTimeout = (fn: () => void, delay: number) => {
  const start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) fn()
      else rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const requestInterval = (fn: () => void, delay: number) => {
  let start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) {
        fn()
        start = new Date().getTime()
      }
      rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const handleRequestError = (err: any) => {
  // The error is already handled
  if (err?.code) return { status: err.code, data: err }

  // The request don't have the response
  if (!err?.response && err.request) {
    return { status: 403, data: { code: 403, msg: err?.message } }
  }

  // The error don't have the response, mean it not Request error
  if (!err?.response) {
    return { status: 500, data: { code: 500, msg: err?.message || 'Unknown error' } }
  }

  // The formatted error return by Backend
  if (err.response.data?.code) return { status: err.response.status, data: err.response.data }

  // The response error that not handled by Backend
  return {
    status: err.response.status,
    data: { code: err.response.status, msg: err.response.statusText, data: err.response.data },
  }
}

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    currency: 'VND',
    style: 'currency',
  })
  return formatter.format(+value)
}

export const numberFormatter = (value: number) => {
  return new Intl.NumberFormat('vi-VN', {}).format(value)
}

// This function to check data integrity does not care about security
export const simpleHash = (message: string) => {
  if (message.length === 0) return 0
  return message.split('').reduce((acc, c) => +((acc << 5) - acc + c.charCodeAt(0)), 0)
}

export const sha1Hash = async (message: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  return await window.crypto.subtle.digest('SHA-1', data)
}

export const getMicrositeUrl = (site: string, tag?: string) =>
  `${process.env.SHOPEE_URL}/m/${site}${tag ? `#${tag}` : ''}`

export const getMicrositeUniversalUrl = (site: string) =>
  `${process.env.SHOPEE_URL}/universal-link/m/${site}?deep_and_deffered=1`

export const randomID = (): string => `_${Math.random().toString(36).substr(2, 9)}`

export const checkIsLogin = () => {
  const userId = getCookie('SPC_U')
  return userId !== null && userId !== '-'
}

export const removeItemByValue = (arr: Array<string | number>, value: string | number) => {
  arr.forEach((e: string | number, i: number) => {
    if (e === value) arr.splice(i, 1)
  })

  return arr
}

export const getRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

export const isLargeScreen = () => (typeof window === 'undefined' ? false : window.innerWidth >= 960)

export const parseTextWithColor = (str: string) => {
  const textColorArr: [string, [string]] = JSON.parse(str)
  const [text, [color]] = textColorArr
  return { text, color }
}

let toastTimer: ITimer
export const showToast = (payload: IShowToastPayload, timeout = 3000) => {
  const { toast } = store.getState()
  const hideToast = () => store.dispatch(toastActions.hideToast())
  // Handle the logic if there are another toast want to show up but previous toast haven't finished yet
  if (toast.isShow) {
    hideToast()
    toastTimer?.clear()
  }

  store.dispatch(toastActions.showToast(payload))
  toastTimer = requestTimeout(hideToast, timeout)
}

export const getShopeeShopUrl = (shopId: number) => {
  return `${process.env.SHOPEE_URL}/shop/${shopId}`
}

export const getShopeeItemUrl = (shopId: number, itemId: number) => {
  return `${process.env.SHOPEE_URL}/spvn-i.${shopId}.${itemId}`
}

export const getTaskActionText = (type: TaskType) => {
  let actionText = ''

  switch (type) {
    case 'act_follow_shop':
      actionText = i18next.t('Theo dõi')
      break
    case 'act_view_shop':
      actionText = i18next.t('Ghé thăm')
      break
    case 'act_view_item':
      actionText = i18next.t('Xem ngay')
      break
    case 'act_like_item':
      actionText = i18next.t('Thực hiện')
      break
    default:
      break
  }

  return actionText
}

export const executeTask = async (task: ITask) => {
  const {
    id: taskId,
    assets: { shop_id, item_id },
    action_type,
  } = task

  if (action_type === 'act_input_code') {
    store.dispatch(closeDialog({ dialogType: DialogType.TaskDialog }))
    store.dispatch(openDialog({ dialogType: DialogType.RedeemCodeDialog, taskId }))
    return
  }

  //* persist to show the task dialog when back from other pages
  setPersistentDialog(DialogType.TaskDialog, false)

  if ((action_type === 'act_view_shop' || action_type === 'act_view_item') && task.status === 'AVAILABLE')
    await store.dispatch(
      taskAPISlice.endpoints.performTask.initiate({
        eventCode: router.query.cid as string,
        taskId,
      }),
    )

  const info = {
    operation: 'click',
    page_section: 'quest_popup_status_button',
    data: {
      game_activity_id: `${getSlotId()}`,
      game_slot_id: `${getSlotId()}`,
      status: 1,
      task_name: task.task_name,
      task_type: task.action_type,
      task_status: task.status === 'AVAILABLE' ? 0 : 1,
    },
  }

  // console.log('track event #8:', info)
  await sendTrackEvent(info, { immediate: true })

  if (action_type === 'act_view_item' || action_type === 'act_like_item')
    micrositeNavigate(getShopeeItemUrl(shop_id, item_id))

  if (action_type === 'act_follow_shop' || action_type === 'act_view_shop') micrositeNavigate(getShopeeShopUrl(shop_id))
}

export const getUnifiedLink = (url: string) =>
  `${process.env.SHOPEE_URL}/universal-link?redir=${encodeURIComponent(decodeURIComponent(url))}&deep_and_deferred=1`

export const getSharePageLink = (objectType: ShareObjectType) => {
  const { cid, site } = router.query

  //* use document.referrer to check that we are in web or app
  const from = document.referrer ? 'web' : 'app'

  return `${process.env.BASE_URL}/share?cid=${cid}&site=${site}&object_type=${objectType}&from=${from}`
}

export const getImage = (image: string) =>
  `${process.env.ENV_ARG === 'dev' ? '' : process.env.BASE_URL}/images/${image}`

export const getCFImage = ({
  url,
  isHash = false,
  isTiny = false,
}: {
  url: string
  isHash?: boolean
  isTiny?: boolean
}) => {
  if (!url) return ''
  return `${isHash ? `https://cf.shopee.vn/file/${url}` : url}${isTiny ? '_tn' : ''}`
}

export let timeOffset = 0
export const setTimeOffset = (serverTime?: number) => {
  if (!serverTime) return null
  timeOffset = Date.now() - serverTime
}

export const isValidHttpUrl = (str: string) => {
  let url: URL

  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const imageLoader = ({ src, width, quality = 75 }) => {
  //* for dev purpose, also a flag to turn on/off image optimized
  return `${
    process.env.ENV_ARG === 'dev' || !process.env.OPTIMIZE_IMAGE
      ? `${src}?`
      : `https://next-image-loader-serverless-awvyuy6x5a-as.a.run.app?url=${encodeURIComponent(src)}&`
  }w=${Math.min(width, 1080)}&q=${quality}`
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const getEventStatus = (timeToOngoing: number, timeToEnd: number): EventStatus => {
  if (timeToOngoing > 0) {
    return 'up-coming'
  } else if (timeToEnd >= 0) {
    return 'on-going'
  }
  return 'ended'
}

export const getSizeByBoxesNumber = (boxes: number): BoxSizeType => {
  if (window.innerWidth <= 280) return 'extraSmall'
  if (boxes === 1) return 'large'
  else if (boxes >= 2 && boxes <= 4) return 'medium'
  return 'small'
}

export const handleShareNavigation = async (objectType: ShareObjectType, isDone: 1 | 0) => {
  //* persist to show the task dialog when back from other pages
  if (objectType === 'event') setPersistentDialog(DialogType.TaskDialog, false)

  const info = {
    operation: 'click',
    page_section: 'quest_popup_status_button',
    data: {
      game_activity_id: `${getSlotId()}`,
      game_slot_id: `${getSlotId()}`,
      status: 1,
      task_name: 'Share',
      task_type: 'act_share',
      task_status: isDone,
    },
  }

  // console.log('track event #8:', info)
  await sendTrackEvent(info, { immediate: true })

  if (isPC()) micrositeNavigate(`${process.env.BASE_URL}/pending/`)
  else micrositeNavigate(getUnifiedLink(getSharePageLink(objectType)))
}

let tracker
let immediateTracker
export const sendTrackEvent = async (info, options: { immediate: boolean } = { immediate: false }) => {
  if (!tracker) tracker = (await import('./tracker')).default
  if (!immediateTracker && options.immediate) immediateTracker = (await import('./tracker')).immediateTracker

  const data = {
    type: 'v3',
    source: 'h5',
    timestamp: +new Date(),
    info: {
      page_type: 'game_mystery_box',
      target_type: '',
      page_section: '',
      ...info,
    },
  }

  return new Promise((resolve) => {
    if (options.immediate) {
      immediateTracker.sync({ data }).then(() => resolve(undefined))
    } else tracker.sync({ data }).then(() => resolve(undefined))
  })
}

export const eventStatusToInt = (status: EventStatus) => {
  if (status === 'up-coming') return 0
  if (status === 'on-going') return 1
  return 2
}

let SLOT_ID = 0
export const setSlotId = (id: number): void => {
  SLOT_ID = id
}

export const getSlotId = () => {
  return SLOT_ID
}

export const trackingViewMoreTaskDialogAction = () => {
  const info = {
    operation: 'click',
    page_section: 'quest_popup_load_more_task_button',
    data: {
      game_activity_id: `${getSlotId()}`,
      game_slot_id: `${getSlotId()}`,
      status: 1,
    },
  }

  // console.log('track event #9', info)
  sendTrackEvent(info)
}

export const trackingCloseTaskDialogAction = () => {
  const info = {
    operation: 'click',
    page_section: 'quest_popup_close_button',
    data: {
      game_activity_id: `${getSlotId()}`,
      game_slot_id: `${getSlotId()}`,
      status: 1,
    },
  }

  // console.log('track event #10', info)
  sendTrackEvent(info)
}

export const thousandSeparatorNumber = ({ num }: { num: number }) => {
  if (num >= 1000000000) {
    const temp = Math.floor(num / 1000000000)
    return `${temp}+ tỷ`
  } else {
    return Intl.NumberFormat('de-DE').format(num)
  }
}
