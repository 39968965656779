/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getMicrositeUrl, requestTimeout, getCFImage } from './utils'
import i18next from 'i18next'
import { ShareSNSType } from 'types'
import {
  callHandler,
  getAppInfo,
  GetAppInfoResponse,
  isWebBridgeSupported,
  showToast,
  WebBridgeCommand,
} from '@shopee/web-bridge-sdk'
import router from 'next/router'

export interface IShareResult {
  errorCode: number
  errorMessage: string
}

export interface ISharingData {
  url: string
  content: string
  img: string
}

export interface IAppInfoResult {
  deviceID: string
  appDeviceID: string
  deviceFingerPrint: string
  BCAXOWidgetDeviceID: string
}

export interface IGetRecentImageResult {
  error: number
  errorMessage: string
  data: {
    thumb: string
    image: string
  }
}

export interface ISignatureData {
  from_user_id: number
  to_user_id: number
  msg_type: number
  entrypoint: number
}

export interface ICheckVersionResult {
  version: number
  appver: number | string
}

export interface INavBarOptions {
  title?: string
  showCloseIcon?: number
  hideBackButton?: number
}

export interface IConfigOptions {
  disableReload?: number
  disableBounce?: number
  disableSwipeBack?: number
}

export interface IReminderData {
  startTime: number
  endTime: number
  reminderId?: number
  redirectPath: string
  title: string
  notes?: string // optional
  content?: string // optional
}

export interface IShareResult {
  errorCode: number
  errorMessage: string
}

export interface IAppInfoResult {
  deviceID: string
  appDeviceID: string
  deviceFingerPrint: string
  BCAXOWidgetDeviceID: string
}

export interface IGetRecentImageResult {
  error: number
  errorMessage: string
  data: {
    thumb: string
    image: string
  }
}

export interface IAddReminderResponse {
  error: 0 | 1
  errorMessage: string
  data: {
    reminderId: string
  }
}

export interface ISignatureData {
  from_user_id: number
  to_user_id: number
  msg_type: number
  entrypoint: number
}

export interface ICheckVersionResult {
  version: number
  appver: number | string
}

export interface INavBarOptions {
  title?: string
  showCloseIcon?: number
  hideBackButton?: number
}

export interface IConfigOptions {
  disableReload?: number
  disableBounce?: number
  disableSwipeBack?: number
}

export interface IReminderData {
  startTime: number
  endTime: number
  reminderId?: number
  redirectPath: string
  title: string
  notes?: string // optional
  content?: string // optional
}

export const isShopeeApp = () =>
  typeof window !== 'undefined' && window?.navigator?.userAgent?.toLowerCase().indexOf('shopee') !== -1

export const isIOS = (): boolean => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator?.userAgent || navigator?.vendor
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
}

export const isAndroid = (): boolean => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator?.userAgent || navigator?.vendor
  return /android/i.test(userAgent)
}

export const isPC = () => !isIOS() && !isAndroid()

export const getAppSignature = async (signatureData: ISignatureData) =>
  new Promise<string>((resolve) => {
    const dataValue = {
      to_user_id: signatureData.to_user_id,
      from_user_id: signatureData.from_user_id,
      msg_type: signatureData.msg_type,
      entrypoint: signatureData.entrypoint,
    }
    const param = isIOS() ? { data: dataValue } : dataValue
    //@ts-ignore
    callHandler(WebBridgeCommand.GetLunaManagerValue, param, (result: { data: { value: string } }) => {
      resolve(result.data.value) // The signature
    })
    setTimeout(() => {
      resolve('-')
    }, 2000)
  })

export const getDeviceID = async () => {
  const deviceID: string = await new Promise((resolve) => {
    if (isWebBridgeSupported())
      getAppInfo((res) => {
        const { appDeviceID } = res as GetAppInfoResponse
        resolve(appDeviceID || 'DEVICEIDNOTFOUND')
      })
    else resolve('DEVICEIDNOTFOUND')
  })
  return deviceID
}

const checkUseRnwv = () => {
  let useRnwv = false

  try {
    useRnwv = !!window.top?.ReactNativeWebView
  } catch {
    //
  }

  return useRnwv
}

export const micrositeNavigate = (url?: string) => {
  if (!url) return

  if (checkUseRnwv()) window.top?.ReactNativeWebView.postMessage(JSON.stringify({ cmd: 'navigate', data: url }))
  else window.top?.postMessage(JSON.stringify({ cmd: 'navigate', data: url }), '*')
}

export const micrositeReminder = (reminderData: IReminderData) =>
  new Promise((resolve) => {
    if (checkUseRnwv())
      window.top?.ReactNativeWebView.postMessage(JSON.stringify({ cmd: 'reminder', data: reminderData }))
    else window.top?.postMessage(JSON.stringify({ cmd: 'reminder', data: reminderData }), '*')

    // Must wait for the 500ms the reminder feature to fully triggered before do anything else
    requestTimeout(() => {
      resolve('')
    }, 500)
  })

export const showWebBridgeToast = ({
  message,
  iconType = 'success',
}: {
  message: string
  iconType: 'success' | 'failure'
}) => {
  showToast({
    toast: {
      iconType, // Image on toast message. Ignored by Android. Only used by iOS. Available types: success & failure
      message,
    },
  })
}

export const sharingAppHandler = (
  sharingAppID: ShareSNSType,
  sharingData: ISharingData,
  onSuccess: () => void = () => {},
  onFail?: (shared: boolean, appType: ShareSNSType) => void,
) => {
  const callback = (res: IShareResult | Record<string, unknown>) => {
    if (res.errorCode === 2) {
      showWebBridgeToast({ message: i18next.t('Ứng dụng chưa được cài đặt'), iconType: 'failure' })
      onFail?.(false, sharingAppID)
    } else if (res.errorCode === 0) {
      // if (sharingAppID === 'copyLink') showWebBridgeToast({ message: i18next.t('Đã sao chép'), iconType: 'success' })
      //* For Android devices, we need to self implement visibility change to detect when back to our app
      //* For iOS devices, in some app we don't need to check visibility change
      const noNeedVisibilityChange = isIOS() && ['facebookLink', 'facebookMessenger'].includes(sharingAppID)
      if (noNeedVisibilityChange) onSuccess()
      else {
        const handleOnSuccessWithVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            onSuccess()
            document.removeEventListener('visibilitychange', handleOnSuccessWithVisibilityChange, false)
          }
        }
        document.addEventListener('visibilitychange', handleOnSuccessWithVisibilityChange, false)
      }
    }
  }
  const { url, content, img } = sharingData
  const BRIDGE_SHARING_DATA = {
    facebookLink: {
      contentUrl: url,
      quote: content,
    },
    facebookMessenger: {
      text: url,
    },
    zaloMessage: {
      text: content,
      url,
    },
    zaloTimeline: {
      url,
    },
    whatsapp: {
      text: url,
    },
    instagram: {
      image: {
        imageUrl: getCFImage({ url: img, isHash: true }),
      },
    },
    lineChat: {
      text: url,
    },
    telegram: {
      text: url,
    },
  }
  callHandler(
    WebBridgeCommand.ShareData,
    {
      sharingAppID,
      sharingData: BRIDGE_SHARING_DATA[sharingAppID],
    },
    callback,
  )
}

export const micrositeLogin = (site: string, callback?: () => void) => {
  const shopeeLoginPage = `${process.env.SHOPEE_URL}/buyer/login?next=${getMicrositeUrl(site, 'mb')}`

  if (!isShopeeApp()) {
    router.push(shopeeLoginPage)
  }

  if (isAndroid() && !document.referrer) {
    //* Since Android Shopee App have a weird implementation on login, we must have a different flow for it
    micrositeNavigate(`${process.env.BASE_URL}/login?site=${site}`)
  } else {
    micrositeNavigate(shopeeLoginPage)

    //* This handle is use for iOS Shopee App, since it will only close LoginPanel
    const handleReturnFromLogin = () => {
      if (document.visibilityState === 'visible') {
        requestTimeout(() => {
          callback?.()
        }, 100)
        document.removeEventListener('visibilitychange', handleReturnFromLogin, false)
      }
    }
    document.addEventListener('visibilitychange', handleReturnFromLogin, false)
  }
}

// export const webBridgeLogin = (redirectPath: string) =>
//   new Promise((resolve, reject) => {
//     login(
//       {
//         hidePopup: 1,
//         redirectTab: 1,
//         redirectPath,
//       },
//       (res) => {
//         const { error } = res as LoginResponse
//         if (error === 0) {
//           resolve(res)
//         } else {
//           reject(res)
//         }
//       },
//     )
//   })
